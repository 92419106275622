export const userDetails = async (userMail, token) => {

  const baseUrl = sessionStorage.getItem('swiss.api.baseUrl');
  const response = await fetch(`${baseUrl}/api/getUserDetails?mail=${encodeURIComponent(userMail)}`, {
    method: 'GET',
    headers: {
      "authorization" : `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  console.log("Data from frontend", data);
    return data;
};