// src/components/Header.js
import React from 'react';
import './Header.css';
 
const Header = () => (
  <header className="chat-header">
    <div>
      <img
        className="bot-img"
        src={require("../assets/SP_Logo_white_cmyk.png")}
        alt="bot logo"
      ></img>
    </div>
    <div className='chat-header-swiss'>
      <span className="head"> IT Helpdesk Chat </span>
    </div>
  </header>
);
 
export default Header;
 