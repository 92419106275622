// Card.js
import React from 'react';
import './Card.css'; // Create a CSS file for styling the card

const Card = ({ title, itService, requestItem, summary, impactedUser }) => {
  return (
    <div className="card">
      <h3>{title}</h3>
      <p>{itService}</p>
      <p>{requestItem}</p>
      <p>{summary}</p>
      <p>{impactedUser}</p>
    </div>
  );
};

export default Card;
