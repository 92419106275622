// mappings.js
export const ITServiceMapping = {
  'Application & Software': 2,
  'Hardware': 1,
  'User Account': 3,
  'IT Security & Risk': 7,
  'Microsoft Office': 4,
  'Network': 5,
  'Reporting & Analytics': 6,
  "I Don't Know": 8,
};

export const RequestedItemMapping = {
  'Application & Software': {
    "Software Access>Sign in Error":1,
    "Software Access>Application Missing":2,
    "Software Access>Other":3,
    "Software Access>Multi User Issue":4,
    "Software Performance>Software Freezing":5,
    "Software Performance>Errors within the Application":6,
    "Software Performance>Permission Issues":7,
    "Software Performance>Multi User issue":8,
    "Query>Incorrect Ticket Type":9,
    "Query>How do i?":10,
    "Citrix Access>Unable To Launch":11,
    "Citrix Access>Sign in Error":12,
    "Citrix Access>Multi User access issues":13,
    "Citrix Access>Other":14,
    "Citrix Access>Request access":15,
    "Citrix Access>Change Access":16,
    "Citrix Performance>Frozen Session":17,
    "Citrix Performance>Black/Grey Screen":18,
    "Citrix Performance>General Slowness(Degraded)":19,
    "Citrix Performance>Multi User Performance Issues":20,
    "Citrix Performance>Other":21,
    "Citrix Application>Missing Icon":22,
    "Citrix Application>Unable to Sign in":23,
    "Citrix Application>Application not Responding":24,
    "Citrix Application>Multi User  Application Performance Issue":25,
    "Citrix Application>Multi User Error":26,
    "Citrix Application>Outlook Profile Error":27,
    "Citrix Application>Other":28,
    "Query>Incorrect Ticket Type":42,
    "Query>How Do I?":43,
    "Query>How Do I?":59,
    "Query>How Do I?":81,
    "Query>Incorrect Ticket Type":132,
    "Query>How Do I?":133,
    "Query>Security Awareness Training":150,
    "Query>How Do I?":151
  },
  'User Account': {
    "Active Directory>New user account":29,
    "Active Directory>Login Error":30,
    "Active Directory>License Incorrect":31,
    "Active Directory>Other":32,
    "SSPR>Unable to Register":33,
    "SSPR>Unable to Reset Password":34,
    "SSPR>Other":35,
    "MFA>MFA Blocked":36,
    "MFA>MFA reset Required":37,
    "MFA>Other":38,
    "SSO>SSO No longer Available":39,
    "SSO>SSO Login Error":40,
    "SSO>Other":41
  },
  'Microsoft Office': {
    "Performance>Freezing":44,
    "Performance>Black/Grey Screen":45,
    "Performance>General Slowness(Degraded)":46,
    "Performance>Multi User Performance Issues":47,
    "Performance>OST File Error":48,
    "Performance>Other":49,
    "Performance>":50,
    "Access>Unable to Sign in":53,
    "Access>Access Permissions issue":54,
    "Access>Multi User Permissions Issue":55,
    "Access>Other":56
},
  'IT Security & Risk': {
    "Other>Other":57,
    "User Security>Password Reset":134,
    "User Security>Immediate Leaver":135,
    "User Security>Account Disable":136,
    "User Security>Litigation Hold":137,
    "User Security>User account is disabled/enabled":138,
    "Email Security>Spam":139,
    "Email Security>Phishing Attack":140,
    "Device Security>Isolate device":141,
    "Device Security>Malicious File Removal":142,
    "Device Security>Anti-Virus Scan":143,
    "Device Security>Internet Browser reset":144,
    "Other>Other security incident":145,
    "Other>Support of any audit/inspection":146,
    "Other>Request for security exception":147,
    "Security Incident>Malicious event":152,
    "Security Incident>Lost/Stolen device":153
},
  Network: {
    "Cabled>Slow Internet Connection":60,
    "Cabled>Connection Dropping":61,
    "Cabled>Devices Unable to connect":62,
    "Cabled>Network Not showing/Device Offline":63,
    "Cabled>Other":64,
    "Wireless>Slow internet ":65,
    "Wireless>No wireless network accessible":66,
    "Wireless>Wireless network disconnects":67,
    "Wireless>Other":68,
    "Network>Slow Internet Connection":69,
    "Network>Connection Dropping":70,
    "Network>Devices Unable to connec":71,
    "Network>Network Not showing/Device Offline":72,
    "Network>Other":73,
    "Network>Site power outage":74,
    "Network>Station no internet connectivity ":75,
    "Central Maintenance>Other":78,
    "Central Maintenance>Scheduled vendor maintenance":79
},
  Hardware: {
    "Laptop>Unable to Power On":82,
    "Laptop>Bitlocker Recovery":83,
    "Laptop>Faulty Hardware":84,
    "Laptop>Blue Screen":85,
    "Laptop>Mouse/Keyboard Unresponsive":86,
    "Laptop>Faulty Cables":87,
    "Laptop>No Network Connection":88,
    "Laptop>No Audio":89,
    "Laptop>Driver Error":90,
    "Laptop>Other":91,
    "Desktop>Unable to Power On":92,
    "Desktop>Bitlocker Recovery":93,
    "Desktop>Faulty Hardware":94,
    "Desktop>Blue Screen":95,
    "Desktop>Mouse/Keyboard Unresponsive":96,
    "Desktop>Faulty Cables":97,
    "Desktop>No Network Connection":98,
    "Desktop>No Audio":99,
    "Desktop>Driver Error":100,
    "Desktop>Other":101,
    "Mobile>Unable to Power On":102,
    "Mobile>Bitlocker Recovery":103,
    "Mobile>Faulty Hardware":104,
    "Mobile>Blue Screen":105,
    "Mobile>Mouse/Keyboard Unresponsive":106,
    "Mobile>Faulty Cables":107,
    "Mobile>No Network Connection":108,
    "Mobile>No Audio":109,
    "Mobile>Driver Error":110,
    "Mobile>Other":111,
    "Handheld>Unable to Power On":112,
    "Handheld>Faulty Hardware":113,
    "Handheld>Blue Screen":114,
    "Handheld>Mouse/Keyboard Unresponsive":115,
    "Handheld>Faulty Cables":116,
    "Handheld>No Network Connection":117,
    "Handheld>No Audio":118,
      "Handheld>Driver Error":119,
    "Handheld>Software Missing":120,
    "Handheld>Other":121,
    "Peripherals>Faulty Hardware":122,
   "Peripherals>Other":123,
      "Server>Server Not Available":124,
    "Server>Server Performance issues":125,
    "Server>Other":126,
    "Printer/Scanner>Unable to print":127,
    "Printer/Scanner>No Toner/Paper":128,
    "Printer/Scanner>Cannot Connect to printer":129,
    "Printer/Scanner>Print Server/Queue issues":130,
    "Printer/Scanner>Printer Displaying Hardware Error":131
},
  'Reporting & Analytics': {
    "DCM>Application Access":155,
    "DCM>General DCM related IT enquiry ":156,
    "MDM>Application Access":157,
    "MDM>General MDM related IT enquiry ":158,
    "Pentaho>ETL":159,
    "Pentaho>Report":160,
    "Pentaho>User Access to Pentaho User Console":162,
    "Pentaho>Report Scheduling ":163,
    "Pentaho>General Pentaho Queries":165,
    "Power BI>Gateway Request":168,
    "Power BI>Workspace Request ":169,
    "Power BI>Report Request ":170,
    "Power BI>Publish as App Request":171,
    "Power BI>Dataset Request":172,
    "Power BI>Deployment Request":173,
    "Power BI>General Power BI Queries":174
  },
  "I Don't Know": {'Not Found': null}
};


