// Card.js
import React from 'react';
import './Card.css'; // Create a CSS file for styling the card

const TKTCard = ({ title, numbertkt, tktsummary, statetkt }) => {
  return (
    <div className="card">
      <h3>{title}</h3>
      <p>{numbertkt}</p>
      <p>{tktsummary}</p>
      <p>{statetkt}</p>
    </div>
  );
};

export default TKTCard;
