
import React from 'react';
import { useMsal } from '@azure/msal-react';
import {loginRequest} from './authConfig'
import * as microsoftTeams from "@microsoft/teams-js";
import { msalConfig } from './authConfig';
import * as msal from "@azure/msal-browser";

 
const Login = () => {
    const { instance } = useMsal();

    try{
   
        console.log("Inside Login.js")
        console.log("Process ", process.env.NODE_ENV);
        
        instance.loginRedirect();
        instance.loginPopup(loginRequest).then(function(result){
            //window.location.href = document.referrer
            console.log("Poping uppppppppppp:::")
        });
    
    }
    catch(error){
        console.error("Error inside login.js", error)
    }    

   
};

export default Login;