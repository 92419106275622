// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { msalConfig, loginRequest } from './authConfig';
// import { PublicClientApplication, Configuration } from '@azure/msal-browser';
// import { MsalProvider} from '@azure/msal-react';

// const root = ReactDOM.createRoot(document.getElementById('root'));

// const msalInstance = new PublicClientApplication(msalConfig);
// const AppWithAuthentication = () => (
//   <MsalProvider instance={msalInstance}>
//       <App />
//   </MsalProvider>
// );
// root.render(
//   // <React.StrictMode>
//   //   <App />
//   // </React.StrictMode>
//   <AppWithAuthentication />
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals();

// // ReactDOM.render(<AppWithAuthentication />, document.getElementById("root"));



//After this I am adding Swissport Dev

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalConfig, loginRequest } from './authConfig';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { MsalProvider} from '@azure/msal-react';
//import microsoftTeams from "@microsoft/teams-js";


console.log("inside Index.js:::::")
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  //<AppWithAuthentication />
  <App/>

);