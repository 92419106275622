// itServices.js
export const ITServices = {
  'Application & Software': [
  'Software Access>Sign in Error',
  'Software Access>Application Missing',
  'Software Access>Other',
  'Software Access>Multi User Issue',
  'Software Performance>Software Freezing',
  'Software Performance>Errors within the Application',
  'Software Performance>Permission Issues',
  'Software Performance>Multi User issue',
  'Query>How do I?',
  'Citrix Access>Unable To Launch',
  'Citrix Access>Sign in Error',
  'Citrix Access>Multi User access issues',
  'Citrix Access>Other',
  'Citrix Access>Request access',
  'Citrix Access>Change Access',
  'Citrix Performance>Frozen Session',
  'Citrix Performance>Black/Grey Screen',
  'Citrix Performance>General Slowness(Degraded)',
  'Citrix Performance>Multi User Performance Issues',
  'Citrix Performance>Other',
  'Citrix Application>Missing Icon',
  'Citrix Application>Unable to Sign in',
  'Citrix Application>Application not Responding',
  'Citrix Application>Multi User  Application Performance Issue',
  'Citrix Application>Multi User Error',
  'Citrix Application>Outlook Profile Error',
  'Citrix Application>Other',
  'Query>Security Awareness Training',
  'Query>Incorrect Ticket Type'
],
'User Account': [
  'Active Directory>New user account',
  'Active Directory>Login Error',
  'Active Directory>License Incorrect',
  'Active Directory>Other',
  'SSPR>Unable to Register',
  'SSPR>Unable to Reset Password',
  'SSPR>Other',
  'MFA>MFA Blocked',
  'MFA>MFA reset Required',
  'MFA>Other',
  'SSO>SSO No longer Available',
  'SSO>SSO Login Error',
  'SSO>Other',
  'Query>How do I?',
  'Query>Incorrect Ticket Type'
],
'Microsoft Office': [
  'Performance>Freezing',
  'Performance>Black/Grey Screen',
  'Performance>General Slowness(Degraded)',
  'Performance>Multi User Performance Issues',
  'Performance>OST File Error',
  'Performance>Other',
  'Performance>',
  'Access>Unable to Sign in',
  'Access>Access Permissions issue',
  'Access>Multi User Permissions Issue',
  'Access>Other',
  'Query>How do I?',
  'Query>Incorrect Ticket Type'
],
'IT Security & Risk': [
  'Other>Other',
  'User Security>Password Reset',
  'User Security>Immediate Leaver',
  'User Security>Account Disable',
  'User Security>Litigation Hold',
  'User Security>User account is disabled/enabled',
  'Email Security>Spam',
  'Email Security>Phishing Attack',
  'Device Security>Isolate device',
  'Device Security>Malicious File Removal',
  'Device Security>Anti-Virus Scan',
  'Device Security>Internet Browser reset',
  'Other>Other security incident',
  'Other>Support of any audit/inspection',
  'Other>Request for security exception',
  'Security Incident>Malicious event',
  'Security Incident>Lost/Stolen device',
  'Query>How do I?',
  'Query>Incorrect Ticket Type'
],
'Network': [
  'Cabled>Slow Internet Connection',
  'Cabled>Connection Dropping',
  'Cabled>Devices Unable to connect',
  'Cabled>Network Not showing/Device Offline',
  'Cabled>Other',
  'Wireless>Slow internet ',
  'Wireless>No wireless network accessible',
  'Wireless>Wireless network disconnects',
  'Wireless>Other',
  'Network>Slow Internet Connection',
  'Network>Connection Dropping',
  'Network>Devices Unable to connec',
  'Network>Network Not showing/Device Offline',
  'Network>Other',
  'Network>Site power outage',
  'Network>Station no internet connectivity ',
  'Central Maintenance>Other',
  'Central Maintenance>Scheduled vendor maintenance',
  'Query>How do I?',
  'Query>Incorrect Ticket Type'
],
'Hardware': [
  'Laptop>Unable to Power On',
  'Laptop>Bitlocker Recovery',
  'Laptop>Faulty Hardware',
  'Laptop>Blue Screen',
  'Laptop>Mouse/Keyboard Unresponsive',
  'Laptop>Faulty Cables',
  'Laptop>No Network Connection',
  'Laptop>No Audio',
  'Laptop>Driver Error',
  'Laptop>Other',
  'Desktop>Unable to Power On',
  'Desktop>Bitlocker Recovery',
  'Desktop>Faulty Hardware',
  'Desktop>Blue Screen',
  'Desktop>Mouse/Keyboard Unresponsive',
  'Desktop>Faulty Cables',
  'Desktop>No Network Connection',
  'Desktop>No Audio',
  'Desktop>Driver Error',
  'Desktop>Other',
  'Mobile>Unable to Power On',
  'Mobile>Bitlocker Recovery',
  'Mobile>Faulty Hardware',
  'Mobile>Blue Screen',
  'Mobile>Mouse/Keyboard Unresponsive',
  'Mobile>Faulty Cables',
  'Mobile>No Network Connection',
  'Mobile>No Audio',
  'Mobile>Driver Error',
  'Mobile>Other',
  'Handheld>Unable to Power On',
  'Handheld>Faulty Hardware',
  'Handheld>Blue Screen',
  'Handheld>Mouse/Keyboard Unresponsive',
  'Handheld>Faulty Cables',
  'Handheld>No Network Connection',
  'Handheld>No Audio',
  'Handheld>Driver Error',
  'Handheld>Software Missing',
  'Handheld>Other',
  'Peripherals>Faulty Hardware',
  'Peripherals>Other',
  'Server>Server Not Available',
  'Server>Server Performance issues',
  'Server>Other',
  'Printer/Scanner>Unable to print',
  'Printer/Scanner>No Toner/Paper',
  'Printer/Scanner>Cannot Connect to printer',
  'Printer/Scanner>Print Server/Queue issues',
  'Printer/Scanner>Printer Displaying Hardware Error',
  'Query>How do I?',
  'Query>Incorrect Ticket Type'
],
'Reporting & Analytics': [
  'DCM>Application Access',
  'DCM>General DCM related IT enquiry ',
  'MDM>Application Access',
  'MDM>General MDM related IT enquiry ',
  'Pentaho>ETL',
  'Pentaho>Report',
  'Pentaho>User Access to Pentaho User Console',
  'Pentaho>Report Scheduling ',
  'Pentaho>General Pentaho Queries',
  'Power BI>Gateway Request',
  'Power BI>Workspace Request ',
  'Power BI>Report Request ',
  'Power BI>Publish as App Request',
  'Power BI>Dataset Request',
  'Power BI>Deployment Request',
  'Power BI>General Power BI Queries',
  'Query>How do I?',
  'Query>Incorrect Ticket Type'
],
"I Don't Know": ['Not Found'],
};
