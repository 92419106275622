// src/components/LanguageSelector.js
import React from 'react';

const LanguageSelector = ({ onSelectLanguage }) => {
  const languages = {
    'en': 'English',
    'es': 'Spanish',
    'fr': 'French',
    'de': 'German',
    'it': 'Italian',
    'pt': 'Brazilian Portuguese',
    'ja': 'Japanese',
    'ko': 'Korean'
  };

  return (
    <div className="language-selector">
      {/* <div className="language-class"> */}
      {Object.entries(languages).map(([code, name]) => (
        <button key={code} onClick={() => onSelectLanguage(code)} className="language-btn">
          {name}
        </button>
      ))}
      {/* </div> */}
    </div>
  );
};

export default LanguageSelector;
